import * as React from "react"
import FormFieldProps from "../../../../../../form/FormFieldProps"
import OutlinedInput from "../../../../../../form/OutlinedInput"
import { validation } from "@social-supermarket/social-supermarket-components"
import { EnquiryFormStateType } from "../EnquiryForm"

const EmailField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<EnquiryFormStateType>) => {
  return (
    <OutlinedInput
      name="email"
      onChange={onChange}
      value={state.email}
      label="Email"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={EmailField.isValid(state)}
    />
  )
}

EmailField.isValid = ({ email }) => {
  return email && validation.validEmailFormat.test(email)
}

export default EmailField
